.current-page {
  font-size: 1.5rem;
  vertical-align: middle;
}

.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
}
.page-link {
  position: relative;
  display: block;
  margin-left: -1px;
  line-height: 0.75;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}
ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
  color: #445565 !important;
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}
ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 0.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 0.75rem;
}
ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}