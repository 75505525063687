.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/*   TEMPORARY POSITION   */
/*                        */
/* Overrides card.min.css */
.hhand-compact {
  /* display: flex;
  justify-content: center; */
}

.vhand-compact {
  /* display: flex;
  flex-direction: column; */
}

.vhand-compact.east {
  /* align-items: flex-end; */
}
.player-hand-container {
  justify-content: center;
}

.player-hand {
  align-items: center;
}

.hand .hcard {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.hand .hcard.east {
  transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

/* Adjustment for rotation */
.hand.player-east, .hand.player-west {
  margin-top: -30px;
  margin-left: -20px;
}

@media screen and (max-width: 767px) {
  .hand.player-west {
    margin-top: -20px;
    margin-left: -5px;
  }
}  
@media screen and (max-width: 767px) {
  .hand.player-east {
    margin-top: -20px;
    margin-left: 30px;
  }
}  
/* .player-hand.vertical-hand {
  flex-direction: row;
}

.player-hand.horizontal-hand {
  flex-direction: column;
} */

/* Card spacing */
.player-hand.horizontal-hand .card {
  /* margin-left: -42px; /* -width * (1.0 - spacing) */
  /* margin-top: 0; */
}

.player-hand.vertical-hand .card {
  /* margin-top: -58px; /* -height * (1.0 - spacing) */
  /* margin-left: 0; */
}

.player-north {
  z-index: 5;
  /* margin-bottom: -80px !important; */
}

.player-south {
  z-index: 5;
  /* margin-top: 100px !important; */
}

  /* Card width */
.card {
  /* width: 60px !important */
}

.card.north.table {
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
}
.card.east.table {
  box-shadow: 5px -5px 5px 0px rgba(0,0,0,0.75);
}
.card.south.table {
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
}
.card.west.table {
  box-shadow: 5px -5px 5px 0px rgba(0,0,0,0.75);
}
/* .hand {
  position: absolute;
}

.player-north {
  top: -75px;
}

.player-south {
  top: 235px;
} */
/* .player-name {
  text-transform: uppercase;
}

.player-name.vertical-hand {
  transform: rotate(270deg);
}
*/
.card.east.table {
  position: absolute;
  right: 40px; /* container width/4 - card height/4 */
  top: 50%;
  margin-top: -40px;
  transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -webkit-transform-origin: 100% 0;
  transform: rotate(90deg) translate(85px, 0);
  -moz-transform: rotate(90deg) translate(85px, 0);
  -webkit-transform: rotate(90deg) translate(85px, 0);
}

.card.west.table {
  position: absolute;
  left: 40px;
  top: 50%;
  margin-top: -40px;
  margin-left: 0;
  transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform: rotate(90deg) translate(0, -98px);
  -moz-transform: rotate(90deg) translate(0, -98px);
  -webkit-transform: rotate(90deg) translate(0, -98px);
}

.card.north.table {
  position: absolute;
  top: 40px;  /* Container height/4 - card height/4 = 260/4 - 98/4 */
  left: 50%;
  margin-left: -35px; /* Half card width of 70/2 */
  margin-top: 0;
}

.card.south.table {
  /* transform: translate(-100px, 50px); */
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -35px; /* assuming card width of 70px */
}

@media screen and (max-width: 767px) {
  .card.east.table {
    right: 31px;
    margin-top: -25px; /* Half container height/2 - card width/2 = 90/2 - 40/2 */
    transform: rotate(90deg) translate(56px, 0);
    -moz-transform: rotate(90deg) translate(56px, 0);
    -webkit-transform: rotate(90deg) translate(56px, 0);
  }
  .card.west.table {
    left: 31px;
    margin-top: -25px; /* Half container height/2 - card width/2 = 90/2 - 40/2 */
    transform: rotate(90deg) translate(0, -56px);
    -moz-transform: rotate(90deg) translate(0, -56px);
    -webkit-transform: rotate(90deg) translate(0, -56px);  
  }
  .card.north.table {
    margin-left: -20px; /* assuming card width of 40px */
    top: 31px;
  }
  .card.south.table {
    margin-left: -20px; /* assuming card width of 40px */
    bottom: 31px;
  }
}
/*
.table {
  width: 450px;
  height: 450px;
  border: 5px solid green;
} */
.trick-history, .bid-history {
  border-spacing: 20px;
}
.liveplay-bid-table {
  border-spacing: 5px;
}
.trick-history td, .auction-cards td, .bid-history td, .liveplay-bid-table td {
  height: 48px;
  width: 48px;
  text-align: center;
  border: 3px solid transparent;
}
.trick-history td, .auction-cards td {
  border: 3px solid black;
  border-radius: 5px;
}
.auction-cards td.blank {
  border: none;
}
.trick-history td.start-player {
  border-color: green;
}
.trick-history td.spade, .trick-history td.club, .auction-cards td.spade, .auction-cards td.club, .bid-history td.spade, .bid-history td.club {
  color: black;
}
.trick-history td.heart, .trick-history td.diamond, .auction-cards td.heart, .auction-cards td.diamond, .bid-history td.heart, .bid-history td.diamond {
  color: #DF343A;
}
.auction-cards td.pass {
  background-color: green;
  color: #fff;
}
.color-spade, .color-club {
  color: #000;
}
.color-diamond, .color-heart, .color-diamonds, .color-hearts {
  color: #DF343A;
}
.bid.selected {
  transform: translate(0px, -10px);
  box-shadow: 8px 8px 3px 0px rgba(0,0,0,0.25);
}

/* React Modal Transitions */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.modal {
  position: absolute;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border-top: 4px;
  border-right: 4px;
  border-bottom: 4px;
  border-left: 4px;
  border-color: #492a2c;
  border-style: solid;
  background-color: #fff;
  color: #000;
  padding: 20px;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  width: 350px;
  /* height: 350px; */
  font-size: 24px;
  font-weight: 700;
}

/* Stop a random blue border appearing around the modal */
.modal:focus {
  outline: 0
}

.modal.bottom {
  top: auto;
  bottom: 0;
  transform: translateX(-50%);
  height: 200px;
}

@media screen and (max-width: 767px) {
  .modal {
    width: 300px;
    /* height: 300px; */
  }

  .liveplay-bid-table td {
    height: 30px;
    width: 30px;
    border: 1px solid transparent;
  }
}

.slide-down-enter {
  max-height: 0px;
  -webkit-transition: max-height 300ms ease;
  transition: max-height 300ms ease;
  overflow: hidden; }

.slide-down-enter-active {
  height: auto;
  max-height: 100px; }

.slide-down-exit {
  max-height: 100px;
  -webkit-transition: max-height 300ms ease;
  transition: max-height 300ms ease; }

.slide-down-exit-active {
  overflow: hidden;
  max-height: 0px; }

/* .speech-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 24px solid transparent;
  border-left-color: #000;
  border-right: 0;
  border-bottom: 0;
  margin-top: -12px;
  margin-right: -24px;
} */

/* .player-south.vhand-compact img, .player-north.vhand-compact img {
  margin-top: -75px !important;
} */