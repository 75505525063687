/* Copied and amended from http://richardschneider.github.io/cardsJS */
img.card {
	/* width: 70px; */
	/* margin: 0; */
	padding: 0;
	border: 0;
	vertical-align: initial;
	box-sizing: initial;
}

/*
 * A hand is a div containing cards.
 */
.hand {
	padding: 0;
	margin: 0;
}
.active-hand img.card { 
	cursor: pointer;
}

/*
 * A horizontal hand, class="hand hhand".  The enitire card is visible.
 */
.hhand {
	display: inline-block; 
}
.hhand img.card { 
	padding-top: 10px;
}
.hhand.active-hand img.card:hover { 
	padding-top: 0px;
	padding-bottom: 10px;
}

/*
 * A vertical hand, class="hand vhand".  The enitire card is visible.
 */
.vhand {
	display: block; 
}
.vhand img.card { 
	padding-right: 10px;
}
.vhand.active-hand img.card:hover { 
	padding-right: 0px;
	padding-left: 10px;
}

/*
 * A compact horizontal hand.  Only the last card is entirely visible.
 */
.hhand-compact { 
	display: inline-block; 
}
.hhand-compact img.card:first-child { 
	margin-left: 0px;
	padding-top: 10px;
}
.hhand-compact img.card { 
	/* margin-left: -52px; */
	padding-top: 10px;
}
.hhand-compact.active-hand img.card:hover { 
	padding-top: 0px;
	padding-bottom: 10px;
}

/*
 * A compact vertical hand.  Only the last card is entirely visible.
 */
.vhand-compact {
	display: inline-block;
	vertical-align: top;
}
.vhand-compact img.card:first-child { 
	display: block; 
	margin-top: 0px;
	padding-right: 10px;
}
.vhand-compact img.card 
{
	display: block; 
	/* margin-top: -80px; */
	padding-right: 10px;
}
.vhand-compact.active-hand img.card:hover,
.active-hand .vhand-compact img.card:hover { 
	display: block; 
	padding-right: 0px;
	padding-left: 10px;
}

/*
 * A fanned hand.  Only the last card is entirely visible.
 */
.fan {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding-bottom: 1em;
}
.fan img.card { 
	position: absolute;
	width: 90px;
}
